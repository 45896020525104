import { capitalize } from '../../TextUtils.js';
import EditStat from '../EntityListTab/EditStat.jsx';
import {getCheckboxValues, setCheckboxValues, poolStatus, getKey} from '../../PoolBoxes.js';
import Sgs from 'sgs';
import React, { Fragment, useState } from 'react';
import PoolCheckboxes from '../../PoolCheckboxes.jsx';
import './PoolBoxes.css';
import CompareProp from '../../CompareProp.js';
import RoundTo from "./RoundTo.js";

function toggleStat (e, sendMessage) {
    let target = e.target;
    let enabled = target.checked;
    let statname = target.dataset.statname;
    let statgroup = target.dataset.statgroup;
    let entityid = target.dataset.entityid;
    let valueType = target.dataset.valuetype
    if(enabled) {
        sendMessage({
            operation: 'add entity stat',
            entityid,
            statgroup,
            statname,
            valueType
        });
    }
    else {
        sendMessage({
            operation: 'remove entity stat',
            entityid,
            statgroup,
            statname,
        });
    }
}
function changeStat (e, sendMessage) {
    // console.log('changestat')
    let entityid = e.target.dataset.entityid;
    let statid = e.target.dataset.statid;
    let statgroup = e.target.dataset.statgroup;
    let value = e.target.value;
    let valueType = e.target.dataset.valuetype;
    // console.log(valueType);
    sendMessage({
        operation: 'change entity stat value',
        entityid,
        statgroup,
        statid,
        value,
        valueType,
    });
}

function changeSort (e, setSortKey) {
    let newSortKey = e.target.dataset.sortkey;
    // console.log(newSortKey);
    setSortKey(newSortKey);
    window.localStorage.setItem('skillSort', newSortKey);
}

function changeFilter (e, setFilterSkills) {
    let newFilterState = e.target.dataset.sortkey;
    setFilterSkills(newFilterState);
    window.localStorage.setItem('filterSkills', newFilterState);
}

export default function StatsPanel(props) {
    let entity = props.entity;
    let doc = props.doc;
    let editable = entity.editable;
    let groupName = props.groupName;
    let sendMessage = props.sendMessage;
    let entityGroup = entity[groupName];
    let docGroup = doc[groupName];
    let isPrimary = groupName === 'primaries';
    let isSkill = groupName === 'skills';
    let isPool = groupName === 'pools';
    const [sortKey, setSortKey] = useState(window.localStorage.getItem('skillSort') || 'name');
    const [filterSkills, setFilterSkills] = useState(window.localStorage.getItem('filterSkills') || false);
    let sortedGroup = docGroup;
    if(isSkill) {
        sortedGroup = docGroup.sort(CompareProp('name')).sort(CompareProp(sortKey));
    }
    
    return (
        <div key={groupName} className={'panel statspanel '+ groupName + 'panel'} >
            <h3 key={groupName}>{capitalize(groupName)} ({RoundTo(entity.xpGroups[groupName].toLocaleString() * entity.xpMultiplier)} xp)</h3>
            <table>
                <thead>
                    <tr>
                        {isSkill &&
                        <td><button
                            data-sortkey='name'
                            onClick={e => changeSort(e, setSortKey)}
                            >
                            {capitalize(groupName)}
                        </button></td>
                        }
                        {!isSkill && <td>{capitalize(groupName)}</td>}
                        <td title={'whether ' + entity.name + ' owns this stat'}>✔</td>
                        <td>Value</td>
                        <td>Xp</td>
                        {isPrimary && <td title='sum of all skill XP tied to this stat'>
                            📊
                        </td>}
                        <td title='XP To Next Level'>TNL</td>
                        {isSkill && <td title='Skill Group'>
                            <button
                                data-sortkey='skillGroup'
                                onClick={e => changeSort(e, setSortKey)}
                            >
                                Grp
                            </button>
                        </td>}
                        {isSkill && <td title='Associated Primary Stat'>Stat</td>}
                        {isSkill && <td title='Total Bonus: Skill + Primary'>Total</td>}
                        {/* {isPool && <td>Boxes</td>} */}
                    </tr>
                </thead>
                <tbody>
                    {sortedGroup.map(prototype => {
                        let stat = entityGroup.find(x => x.name === prototype.name);
                        let classes = !stat ? 'muted' : '';;
                        let tnl = Sgs.Tnl(stat, entity);
                        // console.log(tnl)
                        let untrainedValue = '-';
                        if(groupName.toLowerCase() === 'skills'){
                            // console.log(prototype, stat);
                            let skillStatName = prototype.skillStat;
                            // console.log(entity.primaries);
                            let skillStat = entity.primaries.find(x => x.name == skillStatName);
                            // console.log(skillStat);
                            // console.log(value);
                            untrainedValue = skillStat?.number || 0;
                        }
                        if(groupName === 'primaries' && !!tnl) { tnl *= 2 }
                        return(
                            <React.Fragment key={prototype._id}>
                                <tr key={prototype._id} className={classes}>
                                    <td title={'value type: ' + prototype.valueType}>
                                        {prototype.name}
                                    </td>
                                    <td>
                                        {editable && <input
                                            type='checkbox'
                                            checked={!!stat}
                                            onChange={e => toggleStat(e, sendMessage)}
                                            data-entityid={entity._id}
                                            data-statname={prototype.name}
                                            data-statgroup={groupName}
                                            data-valuetype={prototype.valueType}
                                        />}
                                        {!editable && <input
                                            type='checkbox'
                                            checked={!!stat}
                                            data-entityid={entity._id}
                                            data-statname={prototype.name}
                                            data-statgroup={groupName}
                                            data-valuetype={prototype.valueType}
                                            readOnly
                                            disabled
                                        />}
                                    </td>
                                    <EditStat
                                        key={prototype.name}
                                        prototype={prototype}
                                        stat={stat}
                                        entity={entity}
                                        statgroup={groupName}
                                        changeStat={e => changeStat(e, sendMessage)}
                                        sendMessage={sendMessage}
                                        static={true}
                                    />
                                    <td>{!!stat && !(stat.valueType === 'formula') && RoundTo(stat.xp * entity.xpMultiplier)}</td>
                                    {isPrimary && <td>
                                        {stat?.skillPoints}
                                    </td>}
                                    <td>{RoundTo(tnl )}</td>
                                    {isSkill && <td title={prototype?.skillGroup}>
                                        {prototype?.skillGroup?.substring(0, 3)}
                                    </td>}
                                    {isSkill && <td title={prototype?.skillStat}>
                                        {prototype?.skillStat?.substring(0, 3)}    
                                    </td>}
                                    {isSkill && <td>
                                        {!!stat?.totalBonus && '+'}
                                        {stat?.totalBonus}
                                        {!stat && untrainedValue > -1 && '+'}
                                        {!stat && untrainedValue}
                                    </td>}
                                </tr>
                                {isPool && <tr><td colSpan={5} className='boxcell' >
                                    {PoolCheckboxes(entity, stat)}
                                </td></tr>}
                            </React.Fragment>
                        )})}
                </tbody>
            </table>
        </div>
    )
}

