import React from 'react';
import { withRouter } from 'react-router-dom';
import parse from 'html-react-parser';
import queryString from 'query-string';
import './PrintTab.css';
import generateOutput from './PrintTab/CompileOutput.js';
import Footer from '../Footer.jsx';

class EditorsTab extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            output: '',
        }
    }
    fields = [
        'contentFormat',
        'contentType',
        'whitelistTags',
        'blacklistTags',
        'includeEntities',
        'excludeEntities',
        'sortBy',
    ]

    componentDidMount = () => {
        this.pushHistory();
    }

    generateHeadingUrl = (heading) => {
        let url = heading.toLowerCase().replaceAll(' ', '-');
        return url;
    }

    subPath = () => {
        let printSettings = {};
        this.fields.forEach(field => {
            let value = ''
            if(!!document.getElementById(field)) {
                value = document.getElementById(field).value;
            }
            printSettings[field] = value;
        })
        // console.log(printSettings);
        let newSubpath = '?';
        this.fields.forEach(field => {
            newSubpath += field + '=' +printSettings[field] + '&';
        })
        newSubpath = newSubpath.substring(0, newSubpath.length - 1);
        return newSubpath;
    }
    
    pushHistory = () => {
        let newSubpath = this.subPath();
        // console.log(newSubpath);
        this.props.history.push(newSubpath);
    }
    
    copyToClipboard = (content) => {
        navigator.clipboard.writeText(content)
        .then(() => {
            console.log("copied");
        }, () => {
            console.log("did not copy")
        })
    }
    
    GetApiCall = () => {
        let apiKey = this.props.apiKey;
        let newSubpath = encodeURI(this.subPath());
        // let path = "https://sgs-web.herokuapp.com/api/build";
        let path = "http://localhost:3000/api/build";
        path += newSubpath;
        path += "&docId=" + this.props.doc._id;
        path += "&key=" + apiKey;
        console.log(path);
        navigator.clipboard.writeText(newSubpath)
        .then(() => {
            console.log("copied");
        }, () => {
            console.log("did not copy")
        })
    }

    render() {
        let options = queryString.parse(this.props.location.search);
        // console.log(options);
        if(!options || Object.keys(options).length === 0) {
            options = {
                contentFormat: 'HTML',
                contentType: 'Entity Cards',
                sortBy: 'Xp',
            }
        }
        let doc = this.props.doc;
        let output = generateOutput(options, doc);
        if(options.contentFormat === 'JSON') {
            try {
                output = JSON.stringify(output, null, 2);
            }
            catch(e) {
                console.error(e);
            }
        }
        // console.log(output)
        document.title = [
            'Print ' + options.contentFormat + ' ' + options.contentType,
            doc.name,
            'SGS']
            .join(' - ');
        return(<div>
        <div className='printSettings'>
            <h3>Print</h3>
            <form>
                <label>
                    Content Format
                    <select
                        id='contentFormat'
                        value={options.contentFormat}
                        onChange={this.pushHistory}
                    >
                        <option>HTML</option>
                        <option>Markdown</option>
                        <option>JSON</option>
                    </select>
                </label>
                <label>
                    Content Type
                    <select
                        id='contentType'
                        value={options.contentType}
                        onChange={this.pushHistory}
                    >
                        <option>Entity Cards</option>
                        <option>Item Table</option>
                        <option>Stat Blocks</option>
                        <option>Rules</option>
                    </select>
                </label>
                {options.contentType !== 'Rules' && <div>
                    <label>Sort By:
                        <select
                            id='sortBy'
                            value={options.sortBy}
                            onChange={this.pushHistory}
                        >
                            <optgroup label='Vitals'>
                                <option>Xp</option>
                                <option>Xp Reversed</option>
                                <option>Name</option>
                                <option>Tags</option>
                            </optgroup>
                        </select>
                    </label>
                    <p>If the entity or tag name you're looking for has a comma in it, separate your list with a double comma: "Soldier, Veteran,, Soldier, Newbie". If you're only looking for one with a comma in it, end the line with a double comma: "Soldier, Veteran,," Alternatively, ensure there are no commas in your entity & tag names.</p>
                    <label title='comma separated, no #, if your target contains a comma, separate with two commas: ",,"'>
                        Whitelist Tags:
                        <input
                            id='whitelistTags'
                            value={options.whitelistTags || ''}
                            onChange={this.pushHistory}
                            placeholder='monster, villain, npc'
                        />
                    </label>
                    <label title='comma separated, no #, if your target contains a comma, separate with two commas: ",,"'>
                        Blacklist Tags:
                        <input
                            id='blacklistTags'
                            value={options.blacklistTags || ''}
                            onChange={this.pushHistory}
                            placeholder='item, power'
                        />
                    </label>
                    <div>
                        Tags: {doc?.entityTags?.sort().join(', ')}
                    </div>
                    <label title='comma separated, if your target contains a comma, separate with two commas: ",,"'>
                        Include Entities:
                        <input
                            id='includeEntities'
                            value={options.includeEntities || ''}
                            onChange={this.pushHistory}
                            placeholder='Cloud, Squall, Terra'
                        />
                    </label>
                    <label title='comma separated, if your target contains a comma, separate with two commas: ",,"'>
                        Exclude Entities:
                        <input
                            id='excludeEntities'
                            value={options.excludeEntities || ''}
                            onChange={this.pushHistory}
                            placeholder='super secret superweapon, edge case entity, kevin'
                        />
                    </label>
                    <div>
                        Entities: {doc?.entities?.map(x => x.name).sort().join(', ')}
                    </div>
                </div>}
            
            </form>
            <button onClick={() =>
                this.copyToClipboard(output)
            }>
                Copy All
            </button>
            <button onClick={() => window.print()}>Print</button>
            <button onClick={this.GetApiCall}>Copy API call</button>
        </div>
        {options.contentFormat === 'Markdown' && 
            <pre id='content-output'>
                {output}
            </pre>
        }
        {options.contentFormat === 'HTML' && output && 
            <div
                id='content-output'
                className={options.contentType}
            >
                {parse(output || "")}
            </div>
        }
        {options.contentFormat === 'JSON' && output && 
            <div
                id='content-output'
                className={options.contentType + ' ' + options.contentFormat}
            >
                <pre>{output}</pre>
            </div>
        }
        <Footer/>
        </div>)
    }
}

export default withRouter(EditorsTab);