import Xp from "./Xp.js";
import DicePool from "./DicePool/DicePool.js";

/**
 * Calculates the XP to the next level of the stat.
 * @param {Object} stat the stat you wish to get the TNL of.
 */
export default function Tnl (stat, entity) {
    if(!stat) {
        // console.log('missing stat')
        return '';
    }
    if(!entity) {
        console.log('missing entity')
        return '';
    }
    let prototype = stat.prototype;
    let mod = entity.xpMultiplier;
    let current = Xp(stat) * mod;
    if(!prototype) return '';
    let diceChain = {
        '0': 1,
        '1': 2,
        '2': 4,
        '4': 6,
        '6': 8,
        '8': 10,
        '10': 12,
        '12': 20,
        '20': 100,
        '100': 100,
    };
    let vt = stat.valueType;
    let exponent = stat.prototype.exponent || 2;
    if(vt === 'number') {
        let n = parseInt(stat.number) + 1;
        let sign = Math.sign(n);
        if(!n) stat.number = 0;
        return Math.round((n + (stat.offset || 0)) ** exponent * sign) * mod - current;
    }
    if(vt === 'dieSize' && !!stat.dieSize) {
        if(stat.dieSize === 'none') return 0;
        let size = stat.dieSize.replace('d', '');
        size = diceChain[size];
        let value = Math.round(size / 2) + 1;
        return Math.round((value + (stat.offset || 0)) ** exponent) * mod - current;
    }
    if(vt === 'dicePool' && !!stat.dicePool) {
        let pool = new DicePool(stat.dicePool);
        let total = Math.round(pool.average());
        let sign = Math.sign(total);
        // console.log(stat.dicePool, total);
        return Math.round((total + (stat.offset || 0) + 1) ** exponent * sign) * mod - current;
    }
    if(vt === 'formula' && !!stat.formula) {
        return '';
    }
    return '';
}