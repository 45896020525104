import React from "react";
import UserDataCache from '../Components/UserDataCache.jsx';
import TableOfCampaigns from '../Components/TableOfCampaigns.jsx';
import TableOfDocs from '../Components/TableOfDocs.jsx';
import { CreateConsole } from "../Components/CreateConsole.jsx";
import { Link } from "react-router-dom";
import Spinner from "../Components/Spinner.jsx";
import Footer from "../Components/Footer.jsx";
import PatronList from "../Components/PatronList.jsx";
import TableOfLists from "../Components/TableOfLists.jsx";
import ObjectIsEmpty from "../Components/ObjectIsEmpty.js"

class Home extends React.Component {
    constructor (props){
        super(props)
        // this.id = this.props.match.params.GameId
        this.state = {
            user: {},
            gmCampaigns: [],
            participatingCampaigns: [],
            mydocs: [],
            mylists: [],
            gettingDocs: true,
            gettingPcCampaigns: true,
            gettingGmCampaigns: true,
        }
    }
    
    componentDidMount(){
        this.getUser(() => {
            // console.log(this.state.user)
            this.getGMCampaigns();
            this.getParticipatingCampaigns();
            this.getMyDocs();
            this.getMyLists();
        });
    }

    getUser(cb) {
        let token = UserDataCache.getTokenObject()?.user;
        if(!token || ObjectIsEmpty(token)) return;
        this.setState({ user: token }, () => cb());
    }

    getGMCampaigns = () => {
        if(!this.state.user) return;
        fetch('/api/getGmingCampaigns', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.state.user._id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            }),
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({
                gmCampaigns: results,
                gettingGmCampaigns: false,
            })
        })
    }
    
    getParticipatingCampaigns = () => {
        if(!this.state.user) return;
        fetch('/api/getParticipatingCampaigns', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.state.user._id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            }),
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({
                participatingCampaigns: results,
                gettingPcCampaigns: false,
            })
        })
    }
    getMyDocs = () => {
        if(!this.state.user) return;
        try {
            let localJson = window.localStorage.getItem('userDocs');
            // console.log(localJson);
            if(localJson) {
                let localObj = JSON.parse(localJson);
                // console.log(localObj);
                if(localObj) {
                    this.setState({
                        mydocs: localObj,
                    })
                }
            }
        }
        catch(e) {
            console.log('Error parsing cached document json', e)
        }
        fetch('/api/getMyDocs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.state.user._id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            }),
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({
                mydocs: results,
                gettingDocs: false,
            })
            // add docs to localstorage
            let ids = results.map(x => {
                let optimized = {};
                optimized._id = x._id;
                optimized.type = x.type;
                optimized.tags = x.tags;
                optimized.name = x.name;
                optimized.unlisted = x.unlisted;
                return optimized;
            });
            ids = JSON.stringify(ids);
            // console.log(ids);
            window.localStorage.setItem('userDocs', ids);
        })
    }

    getMyLists = () => {
        if(!this.state.user) return;
        fetch('/api/getMyLists', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': UserDataCache.getAuthHeader(),
            },
            body: JSON.stringify({
                userId: this.state.user._id,
                requesterId: UserDataCache.getTokenObject()?.user._id,
            }),
        })
        .then(res => res.json())
        .then(results => {
            // console.log(results);
            this.setState({
                mylists: results,
                gettingLists: false,
            })
        })
    }

    render () {
        const token = UserDataCache.getTokenObject();
        const user = token?.user;
        let emailhash = user?.emailhash;
        document.title = ['Dashboard', 'SGS'].join(' - ');
        if(!user){
            return (
                <div className="container">
                    <h1>Spiral Game System</h1>
                    <blockquote>Blow the lid off</blockquote>
                    <p>Welcome to the new SGS homepage.</p>
                    <p>
                        You probably want to either&nbsp;
                        <Link to="/login">log in</Link>
                        &nbsp;or&nbsp;
                        <Link to="/register">register.</Link>
                    </p>
                    <p>
                        If you just want to look around, have a check out our <Link to='/games'>public games</Link>
                    </p>
                    <PatronList />
                </div>
            );
        }
        return (
            <div className="container">
                <h1>
                    Dashboard:&nbsp;
                    <span title={user._id}>
                    <a href={'/user/' + user._id}>{user.username}</a>
                    </span>
                </h1>
                <img
                    src={'https://www.gravatar.com/avatar/'
                    + emailhash
                    + '?d=retro'}
                    alt={user.name + ' avatar'}
                />
                {!!user?._id && <CreateConsole
                    getMyDocs={this.getMyDocs}
                    getGMCampaigns={this.getGMCampaigns}
                    getMyLists={this.getMyLists}
                />}
                <h2>My Docs</h2>
                <TableOfDocs
                    docs={this.state.mydocs}
                />
                {this.state.gettingDocs && <Spinner />}
                <h2>My GM Campaigns</h2>
                <TableOfCampaigns 
                    campaigns={this.state.gmCampaigns}
                />
                {this.state.gettingGmCampaigns && <Spinner />}
                <h2>My Player Campaigns</h2>
                <TableOfCampaigns
                    campaigns={this.state.participatingCampaigns}
                />
                {this.state.gettingPcCampaigns && <Spinner />}
                <h2>My Army Lists</h2>
                <TableOfLists 
                    lists={this.state.mylists}
                />
                {this.state.gettingLists && <Spinner />}
                <Footer/>
            </div>
        )
    }
};

export default Home;