// import EntityCards from "../SocketDoc/PrintTab/EntityCards";
import {default as PrintStatBlocks} from "../SocketDoc/PrintTab/Outputs/StatBlocks.js";
import HtmlFormat from "../SocketDoc/PrintTab/Formats/HtmlFormat.js";
import parse from 'html-react-parser';
import Sgs from 'sgs';
import ObjectIsEmpty from "../ObjectIsEmpty.js";

export default function StatBlocks (props) {
    if(!props.enc) return '';
    let enc = props.enc;
    let enemyTypes = enc.enemyTypes;
    let options = {
        sortBy: 'xp',
        hideHeader: true,
    };
    let contents = enemyTypes.map(type => {
        let doc = Sgs.calculateAllXp(type.doc);
        if(!doc) return '';
        if(ObjectIsEmpty(doc)) return '';
        if(!type.entity) return '';
        options.includeEntities = type.entity.name + ",,";
        let format = HtmlFormat;
        // let content = EntityCards(doc, options, format);
        let content = PrintStatBlocks(doc, options, format);
        // console.log(content)
        return(
            <div key={type.entity._id}>
                {parse(content)}
            </div>
    )});
    return(
        <div className="statblocks">
            {contents}
        </div>
    )
}